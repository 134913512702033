import React from "react";
import SocialMediaButtons from "./SocialMediaButtons";
import ContactForm from "./ContactForm";

const Contact: React.FC = () => (
  <React.Fragment>
    <h2 className="Title">Contact</h2>
    <ContactForm />
    <SocialMediaButtons />
  </React.Fragment>
);

export default Contact;
