import React from "react";
import { WhatsappIcon, EmailIcon, PhoneIcon } from "../../Icons/Icons";
import { MyDetails } from "../../../config/myDetails";

type SocialMediaButtonProps = {
  iconProp: JSX.Element;
  href: string;
  /**
   * Defaults to blank
   */
  target?: HTMLAnchorElement["target"];
};

const SocialMediaButton: React.FC<SocialMediaButtonProps> = ({
  href,
  iconProp,
  target = "_blank",
}) => {
  return (
    <a target={target} href={href} className="SocialMediaButton">
      {iconProp}
    </a>
  );
};

const SocialMediaButtons: React.FC = () => {
  return (
    <div className="SocialMediaButtons">
      <SocialMediaButton
        href={`https://api.whatsapp.com/send?phone=${MyDetails.localeFriendlyContactNumber}`}
        iconProp={<WhatsappIcon />}
      />
      <SocialMediaButton
        href={`tel:+${MyDetails.localeFriendlyContactNumber}`}
        iconProp={<PhoneIcon />}
      />
      <SocialMediaButton
        href={`mailto:${MyDetails.email}`}
        iconProp={<EmailIcon />}
      />
    </div>
  );
};

export default SocialMediaButtons;
