import React from "react";

const currentAge = (birthday: Date = new Date("1990-08-20")) => {
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  const epochYear = 1970;
  return Math.abs(ageDate.getUTCFullYear() - epochYear);
};

export const About: React.FC = () => {
  return (
    <React.Fragment>
      <div className="Text">
        <h2 className="Title">About Me</h2>
        <h4>Hi I'm David Tennant</h4>
        <p>
          I'm a {currentAge()} year old technology enthusiast, born and raised
          in Durban.
        </p>
        <p>
          You can find me at one of 3 places: behind a computer desk tinkering
          with some software or gadget, sipping some coffee at a cafe, or out
          and about exploring the world around me.
        </p>
        <p>
          Being self-taught, I aspire to connect with people who I can learn
          from. I am constantly seeking challenging roles to enable me to expand
          and improve my skill-set.
        </p>
        <p>
          Leading comes naturally to me and I enjoy empowering those around me
          through guidance and teaching.
        </p>
      </div>
      <div className="Image"></div>
    </React.Fragment>
  );
};
