import React from "react";
import { smoothScroll } from "../../../common/utils";

export const LandingPage: React.FC = () => (
  <React.Fragment>
    <div className="Container">
      <div className="FloatingBanner">
        <div className="Content">
          <div className="BannerImage" />
          <h1>Howdy, I'm David</h1>
          <h3>I create software. Continuous learning is my passion.</h3>
          <a
            onClick={e => smoothScroll(e, "About")}
            href="#About"
            className="Button"
          >
            Learn more
          </a>
        </div>
      </div>
    </div>
  </React.Fragment>
);
