import { useState, useEffect, Dispatch, SetStateAction } from "react";

function getWindowDimensions(): WindowDimensions {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/**
 * React hook
 * InnerWidth and innerHeight
 */
export type WindowDimensions = {
  width: number;
  height: number;
};

export function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export type ScrollPos = {
  x: number;
  y: number;
  direction: "up" | "down";
};

export const useScroll = (): ScrollPos => {
  // Set a single object `{ x: ..., y: ..., direction: ... }` once on init
  const [scroll, setScroll]: [
    ScrollPos,
    Dispatch<SetStateAction<ScrollPos>>,
  ] = useState({
    x: document.body.getBoundingClientRect().left,
    y: document.body.getBoundingClientRect().top,
    direction: "down",
  } as ScrollPos);

  const listener = (_e: Event) => {
    // `prev` provides us the previous state: https://reactjs.org/docs/hooks-reference.html#functional-updates
    setScroll((prev: ScrollPos) => ({
      x: document.body.getBoundingClientRect().left,
      y: -document.body.getBoundingClientRect().top,
      // Here we’re comparing the previous state to the current state to get the scroll direction
      direction:
        prev.y > -document.body.getBoundingClientRect().top ? "up" : "down",
    }));
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    // cleanup function occurs on unMount
    return () => window.removeEventListener("scroll", listener);
    // Run `useEffect` only once on mount, so add `, []` after the closing curly brace }
  }, []);

  return scroll;
};
