export const smoothScroll = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  s?: string,
) => {
  e.preventDefault();

  let text;
  if (s) {
    text = s;
  } else {
    text = e.currentTarget.id.replace(/-link$/, "");
  }

  const element = document.getElementById(text);

  if (!element) return;
  const top = element.offsetTop;
  window.scrollTo({ top, behavior: "smooth" });
};
