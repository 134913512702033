import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { FormState, FieldState } from "formstate";

type formDataObject = {
  name: string;
  email: string;
  contactNumber: string;
  message: string;
};

type returnData = XMLHttpRequestEventTarget & {
  target: {
    status: number;
    statusText: string;
    response: string;
  };
};

function sendData(data: formDataObject) {
  const XHR = new XMLHttpRequest(),
    FD = new FormData();

  // Push our data into our FormData object
  for (const key in data) {
    FD.append(key, data[key as keyof formDataObject]);
  }

  // Define what happens on successful data submission
  XHR.addEventListener("load", event => {
    if (XHR.status > 199 && XHR.status < 300) {
      alert(`Successfully Sent!`);
    } else {
      alert("Error submitting contact form");
    }
  });

  // Define what happens in case of error
  XHR.addEventListener("error", function(event) {
    alert("Form send error");
  });

  // Set up our request
  XHR.open("POST", "contactform.php");

  // Send our FormData object; HTTP headers are set automatically
  XHR.send(FD);
}

const requiredField = (fieldName?: string) => {
  const message = fieldName ? `${fieldName} required` : "required field";
  return (val: string) => !val && message;
};

class ContactFormState {
  @observable
  username = new FieldState("").validators(requiredField("Name"));
  @observable
  email = new FieldState("").validators(requiredField("Email address"));
  @observable
  contactNumber = new FieldState("").validators();
  @observable
  message = new FieldState("");

  // Compose fields into a form
  @observable
  form = new FormState({
    username: this.username,
    email: this.email,
    contactNumber: this.contactNumber,
    message: this.message,
  });

  onSubmit = async () => {
    const res = await this.form.validate();

    if (res.hasError) {
      alert(`Contact Form cannot be submitted. ${this.form.error}`);
      return;
    }

    const data = {
      name: this.form.$.username.$,
      email: this.form.$.email.$,
      contactNumber: this.form.$.contactNumber.$,
      message: this.form.$.message.$,
    };
    sendData(data);
  };
}

type ContactFormProps = {};

@observer
class ContactForm extends React.Component {
  @observable
  public form: ContactFormState = new ContactFormState();

  constructor(ContactFormProps: ContactFormProps) {
    super(ContactFormProps);

    //@ts-ignore
    window.dev = {
      mainForm: this.form,
    };
  }

  render() {
    const formData = this.form;
    return (
      <form
        className="ContactForm"
        onSubmit={e => {
          e.preventDefault();
          formData.onSubmit();
        }}
      >
        <div className="FormControl">
          <label>Name:</label>
          <input
            onChange={e => formData.username.onChange(e.target.value)}
            value={formData.username.value}
            type="text"
            placeholder={formData.username.error}
          />
        </div>
        <div className="FormControl">
          <label>Contact No:</label>
          <input
            onChange={e => formData.contactNumber.onChange(e.target.value)}
            value={formData.contactNumber.value}
            type="tel"
          />
        </div>
        <div className="FormControl">
          <label>Email:</label>
          <input
            onChange={e => formData.email.onChange(e.target.value)}
            value={formData.email.value}
            type="email"
            placeholder={formData.email.error}
          />
        </div>
        <div className="FormControl">
          <label>Message:</label>
          <textarea
            onChange={e => formData.message.onChange(e.target.value)}
            value={formData.message.value}
          />
        </div>
        <div className="FormControl">
          <input type="submit" value="Submit" />
        </div>
      </form>
    );
  }
}

export default ContactForm;
