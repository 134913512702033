import React from "react";
import { smoothScroll } from "../../../common/utils";

const Navbar = () => (
  <nav className="Navbar">
    <div className="MenuItems">
      <a id="Home-link" onClick={e => smoothScroll(e)} href="#Home">
        Home
      </a>
      <a id="About-link" onClick={e => smoothScroll(e)} href="#About">
        About Me
      </a>
      <a id="Contact-link" onClick={e => smoothScroll(e)} href="#Contact">
        Contact
      </a>
    </div>
  </nav>
);

export default Navbar;
