import React from "react";
import Contact from "./Contact/Contact";
import { About } from "./About/About";
import { LandingPage } from "./LandingPage/LandingPage";
import { useWindowDimensions } from "../../common/hooks";
import Navbar from "./Navbar/Navbar";
import { Section } from "./Section/Section";

const getHeight = (windowDimensions: { height: number; width: number }) => {
  if (windowDimensions.height > 600 && windowDimensions.width >= 768) {
    return windowDimensions.height;
  }
  return "auto";
};

const Content: React.FC = () => {
  const windowDimensions = useWindowDimensions();

  const pageStyle: React.CSSProperties = {
    height: getHeight(windowDimensions),
  };
  return (
    <React.Fragment>
      <Section
        style={{
          ...pageStyle,
          height:
            pageStyle.height && typeof pageStyle.height === "number"
              ? pageStyle.height - 75
              : pageStyle.height,
        }}
        id="Home"
        pageTitle="LandingPage"
      >
        <LandingPage />
      </Section>
      <Navbar />
      <Section style={{ ...pageStyle }} id="About" pageTitle="About">
        <About />
      </Section>
      <Section style={{ ...pageStyle }} id="Contact" pageTitle="Contact">
        <Contact />
      </Section>
    </React.Fragment>
  );
};

export default Content;
