const contactNumber = "0782510474";
const countryCode = "27";
const localeFriendlyContactNumber = contactNumber.replace(/^0/, countryCode);

export const MyDetails = {
  /**
   * Email. Yep
   */
  email: "david@daveit.co.za",
  /**
   * Google Map link of general area
   */
  location: "https://www.google.co.za/maps/place/Durban+North,+4051",
  /**
   * Contact number in general format
   */
  contactNumber: contactNumber,
  /**
   * Contact number in anchor friendly format for WhatsApp and tel
   */
  localeFriendlyContactNumber: localeFriendlyContactNumber,
};
