import React from "react";

type SectionProps = {
  style: React.CSSProperties;
  pageTitle: string;
  id: string;
};

export const Section: React.FC<SectionProps> = ({
  style,
  children,
  pageTitle,
  id,
}) => (
  <div style={{ ...style }} className={`Section`}>
    <div className={pageTitle} id={id}>
      {children}
    </div>
  </div>
);
